import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';

import { primaryColor } from '../../assets/jss/shared';

export const PROGRESS_BAR_SMALL = 'small';
export const PROGRESS_BAR_MEDIUM = 'medium';
export const PROGRESS_BAR_LARGE = 'large';

const useStyles = makeStyles({
	container: {
		textAlign: 'center',
	},
	label: {
		whiteSpace: 'nowrap',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '4px',
		height: '20px',
		width: (props) => `${props.maxLabelWidthPercent || 200}%`,
		transform: (props) =>
			`translate(-${(100 * (((props.maxLabelWidthPercent || 200) - 100) / 2)) / (props.maxLabelWidthPercent || 200)}%, 0%)`,
	},
	labelInline: {
		width: (props) => `${props.maxLabelWidthPercent || 200}%`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		display: 'inline-block',
	},
	progress: {
		// eslint-disable-next-line no-nested-ternary
		height: (props) => (props.size === PROGRESS_BAR_SMALL ? '6px' : props.size === PROGRESS_BAR_LARGE ? '48px' : '14px'),
		width: '100%',
		backgroundColor: primaryColor[8],
	},
	progressBar: {
		height: '100%',
		width: (props) => `${props.progress}%`,
		backgroundColor: primaryColor[6],
	},
});

const ProgressBar = (props) => {
	const { id, className, size, label, progress, maxLabelWidthPercent } = props;
	const classes = useStyles({ size, progress, maxLabelWidthPercent });

	return (
		<div id={id} className={clsx(classes.container, className)}>
			{label && label.length > 0
				? label.split('\n').map((labelPart, index) => (
						<div key={index} className={classes.label} data-cy="progress-label">
							<span className={classes.labelInline}>{labelPart}</span>
						</div>
					))
				: null}
			<div className={classes.progress} data-cy="progress">
				<div className={classes.progressBar} data-cy="progress-bar" />
			</div>
		</div>
	);
};

ProgressBar.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	size: PropTypes.oneOf([PROGRESS_BAR_SMALL, PROGRESS_BAR_MEDIUM, PROGRESS_BAR_LARGE]),
	label: PropTypes.string,
	progress: PropTypes.number,
	maxLabelWidthPercent: PropTypes.number,
};

ProgressBar.defaultProps = {
	id: undefined,
	className: undefined,
	size: PROGRESS_BAR_SMALL,
	label: undefined,
	progress: 0,
	maxLabelWidthPercent: undefined,
};

export default ProgressBar;
