/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import request from "superagent";
import trimChars from "lodash/fp/trimChars";
import { debounce } from "lodash";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { FOLDER_TYPE_CUSTOM } from "utils/enums/DocumentFolderTypes";
import { endsWithAllowedExtension } from "utils/allowedExtensions";
import { CONTENT_SCROLL_ID } from "utils/contentSize";
import { formatDate } from "utils/date";
import downloadFile from "utils/download";
import { useSignInDialog } from "utils/isSignedIn";
import Document from "./Document";
import DocumentMoveCopyDialog from "./components/DocumentMoveCopyDialog";
import DocumentTopBar from "./components/DocumentTopBar";
import UploadDialog from "./components/UploadDialog";
import getPathType from "./utils/pathType";
import GenericEditor from "components/Editor/GenericEditor";
import clsx from "clsx";

import { SettingsContext } from "contexts/Settings/SettingsContext";

const telemetryPage = "Document Detail";

const useStyles = makeStyles((theme) => ({
	agendaItemDocument: {},
	agendaItemDescription: {
		height: "140px",
		maxHeight: "140px",
		paddingLeft: "8px",
		paddingRight: "50px",
		overflow: "auto",
		"& .ck-content": {
			maxHeight: "60px",
			height: "60px",
		},
		"& .genericEditor": {
			marginTop: 0,
		},
	},
}));

const DocumentContainer = () => {
	const { params: { id: authenticatedId } = {} } = useMatch({ path: "/document/:id", end: true }) || {};
	const { params: { id: publicId } = {} } = useMatch({ path: "/public/document/:id", end: true }) || {};
	const id = authenticatedId || publicId;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [dialogs, setDialogs] = useState({});
	const [document, setDocument] = useState(null);
	const [documentHistory, setDocumentHistory] = useState(null);
	const [accessLogs, setAccessLogs] = useState(null);
	const [editing, setEditing] = useState(false);
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const { agendaItemId } = queryString.parse(location.search) || {};
	const [agendaItem, setAgendaItem] = useState(null);
	const [agendaItemEditor, setAgendaItemEditor] = useState([]);
	const [failedFileUploads, setFailedFileUploads] = useState([]);
	const [savingState, setSavingState] = useState({
		isSaving: false,
		saveStatus: null,
		saveTooltip: "",
		isUpdating: false,
		uploadQueue: [],
		uploadingStatus: null,
		uploadingTooltip: "",
		failedUploads: [],
	});
	const savingStateRef = useRef(savingState);
	const editedDocLoadCount = useRef({ oldModifiedDate: "", count: 0 });
	const { testSite, lite } = useContext(SettingsContext);
	const { extension } = document || {};

	let editableExtensions = [".doc", ".docx", ".ppt", ".pptx", , "wopitest", "wopitestx"];
	//
	if (testSite) {
		editableExtensions = editableExtensions.concat([".htm", ".html"]);
	}

	const handlePrevious = () => {
		if (window.history.length > 1) {
			navigate(-1);
		} else if (document) {
			if (document.parentFolderType > 0 && document.path && document.path.length > 0) {
				const rootFolder = getPathType(document.parentFolderType);
				navigate(
					`/documents/${rootFolder}${
						document.parentFolderType === FOLDER_TYPE_CUSTOM || document.path.length > 1
							? `/${document.path[document.path.length - 1].guid}`
							: ""
					}`,
				);
			} else {
				navigate("/");
			}
		}
	};

	const loadDocument = (id = 0, wopiEditDone) => {
		request
			.get(`${API_HOST}/api/document/${id}/detail`)
			.query({ includePath: true })
			.withCredentials()
			.then((res) => {
				const { body: document } = res || {};
				if (document) {
					setDocument(document);
				}

				if (wopiEditDone && editedDocLoadCount.current.oldModifiedDate == document.dateModified && editedDocLoadCount.current.count < 10) {
					editedDocLoadCount.current.count += 1;
					setTimeout(() => {
						loadDocument(id, true);
					}, 1000);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleLoadHistory = useCallback(
		(page, resolve) => {
			let pageToLoad = page;
			if (documentHistory && documentHistory.page >= pageToLoad) {
				pageToLoad = documentHistory.page + 1;
			}
			request
				.get(`${API_HOST}/api/item/${id}/history`)
				.query({
					page: pageToLoad,
					types: "field,location",
				})
				.withCredentials()
				.then((res) => {
					const {
						body: { total, changeSets },
					} = res || {};
					if (changeSets) {
						let filteredChangeSets = changeSets.filter(
							(changeSet) => changeSet.fieldChanges.length > 0 || changeSet.relationshipChanges.length > 0,
						);

						setDocumentHistory((prev) => {
							const prevChangeSets = (prev || {}).changeSets || [];

							return {
								page: pageToLoad,
								total: changeSets.length > 0 ? total : prevChangeSets.length, // If the API still returns changesets, assume the API total is valid, otherwise set the total to the number of filtered change sets
								changeSets: prevChangeSets.concat(filteredChangeSets),
							};
						});
					}

					resolve();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[documentHistory],
	);

	const handleLoadAccessLogs = (page, resolve) => {
		request
			.get(`${API_HOST}/api/document/${id}/access`)
			.query({
				page,
			})
			.withCredentials()
			.then((res) => {
				const { body: accessLogs = [] } = res || {};
				setAccessLogs(accessLogs);

				resolve();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleRename = useCallback(
		(title) => {
			if (title && title !== document.title) {
				request
					.patch(`${API_HOST}/api/document/${document.guid}/detail`)
					.withCredentials()
					.send({ title })
					.then((res) => {
						setDocument({ ...document, ...res.body });
						setDocumentHistory(null);

						enqueueSnackbar(t("detail.snackbar.rename.success", { title }));
					})
					.catch((err) => {
						showSignIn(err, () => {
							handleRename(title);
						});
					});
			}
		},
		[document],
	);

	const handleHtmlEdit = debounce((_event, editor, field) => {
		const html = editor.getData();
		if (html && html !== document.html && document.canUpdate) {
			request
				.patch(`${API_HOST}/api/document/${document.guid}/detail`)
				.withCredentials()
				.send({ html })
				.then((res) => {
					setDocument({ ...document, ...res.body });
					setDocumentHistory(null);

					enqueueSnackbar(t("detail.snackbar.save.success"));
				})
				.catch((err) => {
					showSignIn(err, () => {
						handleHtmlEdit(html);
					});
				});
		}
	}, 2000);

	const handleDownload = useCallback(() => {
		request
			.get(`${API_HOST}/api/document/${document.guid}/download`)
			.withCredentials()
			.then((res) => {
				const { name, mimeType, document } = res.body;

				downloadFile(name, mimeType, document);
			})
			.catch((err) => {
				showSignIn(err, () => {
					handleDownload();
				});
			});
	}, [document]);

	const handleReplace = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		const invalidFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithAllowedExtension(file.name)) {
				filteredFiles.push(file);
			} else {
				invalidFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			setDialogs({
				upload: {
					files: filteredFiles
						.sort((first, second) =>
							// eslint-disable-next-line no-nested-ternary
							first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
						)
						.map((file) => ({
							file,
							name: trimChars("\\/", file.webkitRelativePath || file.name),
							path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
						})),
					invalidFiles,
				},
			});
		} else if (invalidFiles.length > 0) {
			setDialogs({
				failedUploads: invalidFiles,
			});
		}
	};

	const handleCopyMove = () => {
		setDialogs({ copyMove: true });
	};

	const afterCopyMove = (document, move, path) => {
		enqueueSnackbar(t(`detail.snackbar.${move ? "move" : "copy"}.success`, { path }));

		if (move) {
			setDocument(document);
		} else {
			window.open(`${API_HOST}/home/document/${document.guid}`, "_blank");
		}
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const closeUpload = (failedUploads = []) => {
		if (failedUploads.length === 0) {
			closeDialogs();
		} else {
			setDialogs({
				failedUploads,
			});
		}
	};

	const afterUploadFile = (document) => {
		setDocument((prev) => ({
			...prev,
			extension: document.extension,
			size: document.size,
			dateModified: document.dateModified,
			modifiedBy: document.modifiedBy,
		}));
		setDocumentHistory(null);
	};

	const loadMoreHistory = useCallback(
		({ startIndex }) =>
			new Promise((resolve) => {
				handleLoadHistory(Math.floor(startIndex / 25.0) + 1, resolve);
			}),
		[handleLoadHistory],
	);

	const toggleFieldChanges = (changeSet) => {
		changeSet.expanded = !changeSet.expanded;

		setDocumentHistory((prev) => ({ ...prev }));
	};

	const loadMoreAccessLogs = ({ startIndex }) =>
		new Promise((resolve) => {
			handleLoadAccessLogs(Math.floor(startIndex / 25.0) + 1, resolve);
		});

	const handleExport = () => {
		window.location.href = `/filepro/document/exportaccesslog/${id}/`;
	};

	const handleView = useCallback(
		(changeSet) => {
			window.open(`/document/${document.guid}?changeSetId=${changeSet.changeSetId}`, "_blank");
		},
		[document],
	);

	const handleRestore = useCallback(
		(changeSet) => {
			request
				.post(`${API_HOST}/api/item/${document.guid}/history`)
				.withCredentials()
				.send({ changeSetId: changeSet.changeSetId })
				.then(() => {
					loadDocument(document.guid);
					setDocumentHistory(null);

					enqueueSnackbar(
						t("detail.snackbar.restore.success", {
							dateChanged: formatDate(changeSet.dateChanged, changeSet.dateChanged, undefined, t("app:at"), "", "", true, true, true),
						}),
					);
				})
				.catch((err) => {
					showSignIn(err, () => {
						handleRestore(changeSet);
					});
				});
		},
		[document],
	);

	const editDocument = (id) => {
		const msg = {
			MessageId: "App_IsFrameTrusted",
			SendTime: Date.now(),
		};
		if (window && window.parent) {
			window.parent.postMessage(msg, window.location.origin);
		}
		editedDocLoadCount.current = { oldModifiedDate: "", count: 0 };
		window.open(`/home/document/edit/${id}`, "_blank");
	};

	const editAgendaCoverInWord = (id) => {
		window.open(`/home/document/edit/${id}`, "_blank");
	};

	const handleBack = () => {
		editedDocLoadCount.current.oldModifiedDate = document.dateModified;
		loadDocument(document.guid, true);
		setEditing(false);
	};

	// Agenda item functions
	const loadAgendaItem = (id) => {
		if (id) {
			request
				.get(`${API_HOST}/api/agendaitem/${id}`)
				.withCredentials()
				.then((res) => {
					if (res.body) {
						setAgendaItem(res.body.item);
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadItem(id);
						},
						() => {
							setRequestError(err);
						},
					);
				});
		} else {
			// New item
			saveItem(
				{
					id: 0,
					guid: uuid(),
					name: "",
					text: "",
				},
				true,
			);
		}
	};

	const saveAgendaItem = () => {
		if (agendaItem) {
			setSavingState((prev) => ({
				...prev,
				isSaving: false,
			}));

			request
				.put(`${API_HOST}/api/agendaitem/${agendaItem.guid}`)
				.withCredentials()
				.send(agendaItem)
				.then((res) => {
					if (res.status === 200) {
						setSavingState((prev) => ({
							...prev,
							isSaving: false,
							saveStatus: t("agendaMenu:saved"),
						}));
						handlePrevious();
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						saveItem(agendaItem);
					});
				});
		}
	};

	const createAttachmentsPdf = () => {
		if (agendaItem) {
			request
				.post(`${API_HOST}/api/agendaitem/${agendaItem.guid}/createattachmentspdf`)
				.withCredentials()
				.send({ progressGuid: uuid() })
				.then((res) => {})
				.catch((err) => {});
		}
	};

	const checkUploadStatus = () => {
		const { uploadQueue } = savingState;

		let status = null;
		let tooltip = null;
		for (let i = 0; i < uploadQueue.length; i++) {
			if (!uploadQueue[i].complete) {
				if (tooltip) {
					tooltip += ` / ${uploadQueue[i].file.name}`;
				} else {
					tooltip = uploadQueue[i].file.name;
				}
			}
		}
		if (tooltip) {
			status = t("agendaMenu:uploading");
		}
		setSavingState((prev) => ({
			...prev,
			uploadingStatus: status,
			uploadingTooltip: tooltip,
		}));
	};

	const completeFileUpload = (itemGuid, fileGuid, error) => {
		const { uploadQueue } = savingState;
		let allUploadsComplete = true;

		for (let i = 0; i < uploadQueue.length; i++) {
			if (uploadQueue[i].guid === itemGuid && uploadQueue[i].fileGuid === fileGuid) {
				uploadQueue[i].complete = true;
				uploadQueue[i].error = error;
			}

			if (uploadQueue[i].complete == false) {
				allUploadsComplete = false;
			}
		}

		// Create attachments pdf first time attachments are uploaded
		if (uploadQueue && uploadQueue.length > 0 && allUploadsComplete) {
			saveAgendaItem();
			createAttachmentsPdf();
		}

		setSavingState((prev) => ({
			...prev,
			uploadQueue,
		}));

		checkUploadStatus();
	};

	const sendFiletoAPI = (fileData, isRetry = false) => {
		setSavingState((prev) => ({
			...prev,
			isUploading: true,
		}));

		request
			.post(`${API_HOST}/api/documents/uploadattachments`)
			.withCredentials()
			.send(fileData)
			.then((res) => {
				if (res.status === 200) {
					setSavingState((prev) => ({
						...prev,
						isUploading: false,
						failedUploads: prev.failedUploads.concat(
							(res.body.invalidFiles || []).map((name) => ({
								name,
							})),
						),
					}));

					forEach((attachment) => {
						completeFileUpload(attachment.itemGuid, attachment.guid);
					}, res.body.Attachments);
					checkUploadStatus();
				}
			})
			.catch((err) => {
				const fileError = err.status === 400 || err.status === 500 || isRetry;
				setSavingState((prev) => ({
					...prev,
					isUploading: false,
					failedUploads: prev.failedUploads.concat(
						fileError
							? (err.response.body.invalidFiles || []).map((name) => ({
									name,
								}))
							: [],
					),
				}));
				checkUploadStatus();

				if (!fileError) {
					showSignIn(
						err,
						() => {
							sendFiletoAPI(fileData);
						},
						!isRetry
							? () => {
									// Something went wrong, so wait 5 seconds and try again
									setTimeout(() => {
										sendFiletoAPI(fileData, true);
									}, 5000);
								}
							: undefined,
					);
				}
			});
	};

	const queueFileUploads = (guid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			setSavingState((prev) => {
				prev.uploadQueue.push({
					guid,
					fileGuid: fileUploads[i].guid,
					file: fileUploads[i],
					complete: false,
					error: null,
				});

				return {
					...prev,
					uploadQueue: prev.uploadQueue,
					failedUploads: [],
				};
			});
		}
		checkUploadStatus();

		sendFiletoAPI(fileData);
	};

	const invalidFileExtension = (guid, fileNames) => {
		if (fileNames.length > 0) {
			setFailedFileUploads(fileNames);
		}
	};

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				title: document ? document.title : "",
				back: {
					action: () => navigate(-1),
				},
				telemetryPage,
				contentPaper: { transparent: true },
			}),
		);
		if (document?.title) {
			window.document.title = `${document.title} - ${t("app:productName")}}`;
		}
	}, [document]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadDocument(id);
		if (agendaItemId) {
			loadAgendaItem(agendaItemId);
		}
	}, [id]);

	return (
		<>
			<DocumentTopBar
				handlePrevious={handlePrevious}
				handleBack={handleBack}
				editDocument={
					document && !document.isPolicy && document.canUpdate && editableExtensions.includes(document.extension) ? editDocument : null
				}
				editing={editing}
				savingState={savingState}
				handleSaveAndClose={agendaItem ? saveAgendaItem : null}
				extension={extension}
				telemetryPage={telemetryPage}
				id={id}
				editAgendaCoverInWord={editAgendaCoverInWord}
			/>
			<ComponentContainer padding="72px 0 0 0">
				{document ? (
					<div>
						<div className={clsx({ [classes.agendaItemDocument]: agendaItem })}>
							<Document
								id={CONTENT_SCROLL_ID}
								document={document}
								viewByPublic={Boolean(!authenticatedId && publicId)}
								history={documentHistory}
								accessLogs={accessLogs}
								telemetryPage={telemetryPage}
								handleRename={handleRename}
								handleHtmlEdit={handleHtmlEdit}
								handleDownload={handleDownload}
								handleReplace={handleReplace}
								handleCopyMove={handleCopyMove}
								loadMoreHistory={loadMoreHistory}
								toggleFieldChanges={toggleFieldChanges}
								handleView={handleView}
								handleRestore={handleRestore}
								loadMoreAccessLogs={loadMoreAccessLogs}
								handleExport={handleExport}
								editing={editing}
								agendaItem={agendaItem}
							/>
						</div>
						{agendaItem && agendaItem.canUpdate && (
							<div className={classes.agendaItemDescription}>
								<GenericEditor
									key={`agenda-item-editor`}
									toolbar="limited"
									name="text"
									title={t("agendaItems:editItem.addAttachmentsToDescription")}
									labelSize="large"
									setEditorsData={(editor) => {
										setAgendaItemEditor(editor);
									}}
									onChange={(_event, editor, field) => {
										setAgendaItem((prev) => ({ ...prev, text: editor.getData() }));
									}}
									content={agendaItem.text || ""}
									handleUndo={() => {}}
									handleRedo={() => {}}
									queueFileUploads={queueFileUploads}
									invalidFileExtension={invalidFileExtension}
									guid={agendaItem.guid}
									features={[
										{
											id: "MOA",
											label: t("meetings:inlineFile.features.MOA.featureLabel"),
											className: "closed",
											defaultValue: true,
											disabledValue: false,
											isEnabled: true,
											anchorTitle: t("meetings:inlineFile.features.MOA.anchorTitleMember"),
											tooltipDisabledOn: t("meetings:inlineFile.features.MOA.tooltipDisabledOn"),
										},
									]}
									mt={2}
									disabled={!agendaItem.canUpdate}
								/>
							</div>
						)}
					</div>
				) : (
					<CircularProgressIndicator />
				)}
				{dialogs.upload && (
					<UploadDialog
						replace
						id={document.guid}
						files={dialogs.upload.files}
						invalidFiles={dialogs.upload.invalidFiles}
						onClose={closeUpload}
						afterUploadFile={afterUploadFile}
						showSignIn={showSignIn}
						telemetryPage={telemetryPage}
					/>
				)}
				{dialogs.failedUploads && <UploadErrorDialog failedUploads={dialogs.failedUploads} onClose={closeDialogs} />}
				{dialogs.copyMove && <DocumentMoveCopyDialog document={document} onClose={closeDialogs} afterCopyMove={afterCopyMove} />}
			</ComponentContainer>
		</>
	);
};

export default withErrorHandling(DocumentContainer);
