import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import request from "superagent";
import { v4 as uuid } from "uuid";
import clsx from "clsx";

import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import settingsStyle from "assets/jss/components/settingsStyle";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import SelectInput from "atlas/components/FormControls/SelectInput";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { MEDIUM } from "atlas/utils/buttonSize";
import { Check, Visibility, VisibilityOff } from "components/Icons";
import { API_HOST } from "config/env";
import { updateHandlers, PROGRESS_HUB } from "utils/communication/SignalrClient";
import { formatDate } from "utils/date";
import notifierMessage from "utils/notifierMessage";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useUpdateObject, initializeValidate, setValidate, getRequiredLabel, getErrorProps } from "utils/updateObject";
import { setSnackbarOptions } from "redux/snackBar/actions";
import BoardDocsImportProgress from "./BoardDocsImportProgress";

const useStyles = makeStyles(settingsStyle);

const defaultCredentials = {
	userName: "",
	password: "",
	companyCode: "",
	continueImport: true,
	onlyImportNewUsers: true,
	importWelcomePage: false,
	importAllCommittees: true,
	importOnlyPolicies: false,
	excludePolicies: false,
};
const clearErrors = {
	userName: "",
	password: "",
	companyCode: "",
};
const clearImportingProgress = {
	label: " ",
	percent: 0,
};
const statusCheckTimeout = 60000;

const telemetryPage = "BoardDocs import";

const BoardDocsImport = (props) => {
	const { companyCode, progressGuid, handleChange } = props;
	const { t } = useTranslation("settings");
	const [credentials, setCredentials] = useState({ ...defaultCredentials });
	const [boards, setBoards] = useState(null);
	const [loadingBoards, setLoadingBoards] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [errors, setErrors] = useState({
		...clearErrors,
	});
	const [importing, setImporting] = useState({ active: false, validated: false });
	const [dialogs, setDialogs] = useState({});
	const [progress, setProgress] = useState({
		...clearImportingProgress,
	});
	const [logs, setLogs] = useState([]);
	const [selectedLog, setSelectedlog] = useState(null);
	const guid = useRef();
	const statusCheckHandle = useRef();
	const allowPasswordEdit = useRef(false); // Prevent the browser from automatically generating or setting a password
	const appReducer = useSelector((state) => state.appReducer);
	const {
		signalR: { client, handler },
	} = appReducer;
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleShowPassword = () => setShowPassword((prev) => !prev);

	const validateCredentials = (updatedCredentials, updatedField) => {
		setErrors((prev) => {
			if (!updatedCredentials.userName) {
				prev.userName = t("boardDocs.validation.userName");
			} else {
				prev.userName = "";
			}
			if (!updatedCredentials.password) {
				prev.password = t("boardDocs.validation.password");
			} else {
				prev.password = "";
			}
			if (!updatedCredentials.companyCode) {
				prev.companyCode = t("boardDocs.validation.companyCode");
			} else {
				prev.companyCode = "";
			}

			setValidate(prev, updatedField);
			setImporting((prev) => ({ ...prev, validated: true }));

			return { ...prev };
		});
	};

	const updateCredentials = useUpdateObject(setCredentials, undefined, validateCredentials);

	const hasValidationErrors = (errorsObject) => Boolean(errorsObject.userName || errorsObject.password || errorsObject.companyCode);

	const loadBoards = (credentials) => {
		setLoadingBoards(true);

		request
			.post(`${API_HOST}/api/boarddocs/boards`)
			.withCredentials()
			.send({
				companyCode: credentials.companyCode,
				userName: credentials.userName,
				password: credentials.password,
			})
			.then((res) => {
				const { status, body: boards } = res;

				if (status === 200) {
					setBoards(boards);
				}

				setLoadingBoards(false);
			})
			.catch(() => {
				setLoadingBoards(false);
			});
	};

	const loadLogs = () => {
		request
			.get(`${API_HOST}/api/boarddocs/logs`)
			.withCredentials()
			.then((res) => {
				const { status, body: logs } = res;

				if (status === 200) {
					setLogs(logs);
					setSelectedlog(logs[0]?.name);
				}
			});
	};

	const downloadLog = useCallback(() => {
		window.location.href = `${API_HOST}/api/boarddocs/log?logname=${selectedLog}`;
	}, [selectedLog]);

	const checkImportStatus = () => {
		request
			.get(`${API_HOST}/api/boarddocs/status?progressguid=${guid.current}`)
			.withCredentials()
			.then((res) => {
				const {
					body: { active, complete },
				} = res;

				// Check if the import needs to be restarted
				if (!active && !complete) {
					handleImport(undefined, true);
				} else if (active) {
					scheduleStatusCheck();
				}
			})
			.catch(() => {});
	};

	const scheduleStatusCheck = () => {
		statusCheckHandle.current = setTimeout(checkImportStatus, statusCheckTimeout);
	};

	const clearStatusCheck = () => {
		if (statusCheckHandle.current) {
			clearTimeout(statusCheckHandle.current);
			statusCheckHandle.current = null;
		}
	};

	const handleImport = (_e, restart = false) => {
		if (!restart) {
			setImporting((prev) => ({ ...prev, active: true }));
			clearProgress(true);

			telemetryAddEvent(`${telemetryPage} - BoardDocs Import Data`);
		}

		request
			.post(`${API_HOST}/api/boarddocs/import`)
			.withCredentials()
			.send({
				...credentials,
				boards: boards?.filter((board) => board.selected)?.map((board) => board.boardId) || [],
				progressGuid: guid.current,
			})
			.then((res) => {
				const {
					status,
					body: { errors },
				} = res;

				setCredentials((prev) => ({ ...prev, continueImport: true }));

				if (status === 200) {
					if (errors.signIn) {
						let option = notifierMessage(t("boardDocs.snackbar.invalidCredentials"), "error");
						dispatch(setSnackbarOptions(option));
					} else if (errors.active) {
						let option = notifierMessage(t("boardDocs.snackbar.importActive"), "success");
						dispatch(setSnackbarOptions(option));
					} else {
						// Start the periodic status check
						scheduleStatusCheck();
					}
				}
			})
			.catch(() => {
				setImporting((prev) => ({ ...prev, active: false }));
			});
	};

	const handleCloseProgress = () => {
		clearProgress();
		loadLogs();
	};

	const clearProgress = (show = false) => {
		setDialogs({ importProgress: show });
		setProgress({
			...clearImportingProgress,
		});
	};

	const updateProgress = (percentage, message) => {
		setProgress({
			label: message,
			percent: percentage,
		});
		if (percentage >= 100) {
			if (statusCheckHandle.current) {
				// This is a proxy to see if we have reached 100% once already to avoid duplicate snackbars
				let option = notifierMessage(t("boardDocs.snackbar.success"), "success");
				dispatch(setSnackbarOptions(option));
				setImporting((prev) => ({ ...prev, active: false }));
			}
			clearStatusCheck();
		}
	};

	useEffect(() => {
		guid.current = progressGuid || uuid();

		updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: updateProgress });

		client.ensureStarted().then(() => client.progressHub.registerProgressGuid(guid.current));

		initializeValidate(setErrors, false, clearErrors);

		if (companyCode) {
			setCredentials((prev) => ({ ...prev, companyCode }));
		}

		if (progressGuid) {
			setImporting((prev) => ({ ...prev, active: true }));
			clearProgress(true);
			scheduleStatusCheck();
		}

		loadLogs();

		setTimeout(() => {
			allowPasswordEdit.current = true;
		}, 3000);

		return () => {
			// Clear any pending status check timeouts
			clearStatusCheck();

			client.ensureStarted().then(() => client.progressHub.clearProgressGuid(guid.current));

			updateHandlers(dispatch, handler, PROGRESS_HUB, { announceProgress: null });
		};
	}, []);

	useEffect(() => {
		if (!credentials.importAllCommittees && !boards) {
			loadBoards(credentials);
		}
	}, [credentials, boards]);

	return (
		<>
			{dialogs.importProgress && progress.percent > 0 && (
				<BoardDocsImportProgress
					progress={progress}
					show={Boolean(dialogs.importProgress)}
					handleClose={handleCloseProgress}
				></BoardDocsImportProgress>
			)}
			<div className={classes.labelWithOtherContent}>
				<Typography variant="h4" className={classes.bold}>
					{t("boardDocs.labels.import")}
				</Typography>
			</div>
			<div className={classes.labelWithOtherContent}>
				<OutlinedInput
					id="board-docs-un"
					className={classes.outlinedInput}
					label={getRequiredLabel(t, t("boardDocs.labels.userName"))}
					value={credentials.userName}
					onChange={(e) => {
						setBoards(null);
						updateCredentials(e, "userName");
					}}
					{...getErrorProps(errors, "userName")}
					noDefaultClassName
					fullWidth
					size="small"
					data-cy="board-docs-user-name"
				/>
			</div>
			<div className={classes.labelWithOtherContent}>
				<OutlinedInput
					id="board-docs-pw"
					className={classes.outlinedInput}
					label={getRequiredLabel(t, t("boardDocs.labels.password"))}
					type={showPassword ? "text" : "password"}
					value={credentials.password}
					onChange={(e) => {
						if (allowPasswordEdit.current) {
							setBoards(null);
							updateCredentials(e, "password");
						}
					}}
					{...getErrorProps(errors, "password")}
					noDefaultClassName
					fullWidth
					size="small"
					InputProps={{
						endAdornment: (
							<InputAdornment tabIndex="-1" position="end">
								<IconButton tabIndex="-1" onClick={handleShowPassword} size="large">
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						),
					}}
					data-cy="board-docs-password"
				/>
			</div>
			<div className={classes.labelWithOtherContent}>
				<OutlinedInput
					id="board-docs-company-code"
					className={classes.outlinedInput}
					label={getRequiredLabel(t, t("boardDocs.labels.companyCode"))}
					value={credentials.companyCode}
					onChange={(e) => {
						setBoards(null);
						updateCredentials(e, "companyCode");
						handleChange(e, "boardDocsCompanyCode");
					}}
					{...getErrorProps(errors, "companyCode")}
					noDefaultClassName
					fullWidth
					size="small"
					data-cy="board-docs-company-code"
				/>
			</div>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.continueImport}
							onChange={(e) => updateCredentials(e, "continueImport", true)}
						/>
					}
					label={t("boardDocs.labels.continueImport")}
					data-cy="continue-import"
				/>
			</div>
			{!credentials.continueImport && (
				<div className={classes.subCheckbox}>
					<FormControlLabel
						control={
							<Checkbox
								checkedIcon={<Check fontSize="small" color="primary" />}
								checked={credentials.onlyImportNewUsers}
								onChange={(e) => updateCredentials(e, "onlyImportNewUsers", true)}
							/>
						}
						label={t("boardDocs.labels.onlyImportNewUsers")}
						data-cy="only-import-new-users"
					/>
				</div>
			)}
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.importWelcomePage}
							onChange={(e) => updateCredentials(e, "importWelcomePage", true)}
						/>
					}
					label={t("boardDocs.labels.importWelcomePage")}
					data-cy="import-welcome-page"
				/>
			</div>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.importAllCommittees}
							onChange={(e) => updateCredentials(e, "importAllCommittees", true)}
						/>
					}
					label={t("boardDocs.labels.importAllCommittees")}
					data-cy="import-all-committees"
				/>
			</div>
			{!credentials.importAllCommittees && (
				<div>
					{boards && boards.length > 0 ? (
						<ol className={classes.indentedList} data-cy="committee-list">
							{boards.map((board) => (
								<li key={board.boardId}>
									<FormControlLabel
										control={
											<Checkbox
												checkedIcon={<Check fontSize="small" color="primary" />}
												checked={board.selected}
												onChange={(e) => {
													board.selected = e.target.checked;
													setBoards((prev) => [...prev]);
												}}
											/>
										}
										label={board.name}
										data-cy={`board-${board.boardId}`}
									/>
								</li>
							))}
						</ol>
					) : loadingBoards ? (
						<CircularProgressIndicator size={20} minHeight="20px" />
					) : (
						<div className={classes.indentedList}>
							{t(`boardDocs.validation.${!credentials.companyCode ? "boardsCompanyCode" : "boardsCredentials"}`)}
						</div>
					)}
				</div>
			)}
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.importOnlyPolicies}
							onChange={(e) => {
								updateCredentials(e, "importOnlyPolicies", true);
								updateCredentials({ target: { checked: false } }, "excludePolicies", true);
							}}
						/>
					}
					label={t("boardDocs.labels.importOnlyPolicies")}
					data-cy="import-only-policies"
				/>
			</div>
			<div>
				<FormControlLabel
					control={
						<Checkbox
							checkedIcon={<Check fontSize="small" color="primary" />}
							checked={credentials.excludePolicies}
							onChange={(e) => {
								updateCredentials(e, "excludePolicies", true);
								updateCredentials({ target: { checked: false } }, "importOnlyPolicies", true);
							}}
						/>
					}
					label={t("boardDocs.labels.excludePolicies")}
					data-cy="exclude-policies"
				/>
			</div>
			<div>
				<ButtonWithTooltip
					variant="outlined"
					title={t("boardDocs.tooltips.importData")}
					size={MEDIUM}
					onClick={handleImport}
					data-cy="board-docs-import-data"
					disabled={importing.active || !importing.validated || hasValidationErrors(errors)}
				>
					{t("boardDocs.labels.importData")}
				</ButtonWithTooltip>
			</div>
			{logs.length > 0 ? (
				<>
					<div className={clsx(classes.subSection, classes.communityButtons)}>
						<SelectInput
							id="board-docs-log-files"
							className={classes.selectInput}
							noDefaultClassName
							fullWidth
							size="small"
							label={t("boardDocs.labels.logs")}
							labelSize="large"
							value={selectedLog}
							onChange={(e) => {
								setSelectedlog(e.target?.value);
							}}
							data-cy="board-docs-log-files"
						>
							{logs.map((log) => (
								<MenuItem key={log.name} value={log.name}>
									<div className={classes.menuItemText}>
										{formatDate(log.dateModified, null, null, t("app:at"), t("from"), t("to"), false)}
									</div>
								</MenuItem>
							))}
						</SelectInput>
					</div>
					<div>
						<ButtonWithTooltip
							variant="outlined"
							title={t("boardDocs.tooltips.downloadLog")}
							size={MEDIUM}
							onClick={downloadLog}
							data-cy="board-docs-download-log"
						>
							{t("boardDocs.labels.downloadLog")}
						</ButtonWithTooltip>
					</div>
				</>
			) : null}
		</>
	);
};

export default BoardDocsImport;
