/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useContext, useRef, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from "react-router-dom";
import { useSnackbar } from "notistack";
import request from "superagent";
import trimChars from "lodash/fp/trimChars";
import { debounce } from "lodash";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";

import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import UploadErrorDialog from "components/Dialogs/UploadErrorDialog";
import withErrorHandling from "components/ErrorHOC";
import { API_HOST } from "config/env";
import { resetPageConfigs, updatePageConfigs } from "redux/app/actions";
import { endsWithAllowedExtensionForPolicy } from "utils/allowedExtensions";
import { CONTENT_SCROLL_ID } from "utils/contentSize";
import { formatDate } from "utils/date";
import downloadFile from "utils/download";
import { FOLDER_TYPE_POLICY_REVISION_HISTORY } from "utils/enums/DocumentFolderTypes";
import { useSignInDialog } from "utils/isSignedIn";
import Policy from "views/Policy/Policy";
import PolicyMoveCopyDialog from "views/Policy/components/PolicyMoveCopyDialog";
import DocumentTopBar from "views/Documents/components/DocumentTopBar";
import getPathType from "views/Documents/utils/pathType";
import GenericEditor from "components/Editor/GenericEditor";
import { getPathForEditPage } from "views/Policy/utils/getPathForEditPage";
import { Box } from "@mui/material";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import clsx from "clsx";
import {
	downloadPolicy,
	getLoadHistory,
	publishPolicy,
	unpublishPolicy,
	policyTimeline,
	policyAccessLogs,
	policyRename,
	restorePolicyDetails,
	loadPolicyBook,
} from "../../redux/policyGovernance/actions";

import { SettingsContext } from "contexts/Settings/SettingsContext";
import { UserContext } from "contexts/User/UserContext";
import UploadDialog from "./components/UploadDialog";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
import { GET_PUBLISHED_PERMISSIONS } from "redux/policyGovernance/types";
import FileUploadFailureDialog from "components/Dialogs/FileUploadFailureDialog";
import telemetryAddEvent from "utils/telemetryAddEvent";

const telemetryPage = "Policy Detail";

const useStyles = makeStyles((theme) => ({
	agendaItemDocument: {},
	agendaItemDescription: {
		height: "140px",
		maxHeight: "140px",
		paddingLeft: "8px",
		paddingRight: "50px",
		overflow: "auto",
		"& .ck-content": {
			maxHeight: "60px",
			height: "60px",
		},
		"& .genericEditor": {
			marginTop: 0,
		},
	},
	overlayLoader: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100vw",
		height: "100vh",
		background: "rgb(111,111,111,0.5)",
	},
	loader: {
		zIndex: 10,
	},
	prevAndNextButtons: {
		color: "#FFFFFF",
		borderColor: "#455D82",
		padding: "8px 12px 8px 12px",
		width: "72px",
		height: "40px",
		backgroundColor: "transparent",
		"&:hover": {
			color: "#455D82",
		},
	},

	buttons: {
		display: "flex",
		gap: "8px",
	},
}));

export const PublicDocumentContext = createContext();

const PolicyContainer = () => {
	const { params: { id: authenticatedId } = {} } = useMatch({ path: "/policydoc/:id", end: true }) || {};
	const { params: { id: publicPortalId } = {} } = useMatch({ path: "/policies/policydoc/:id", end: true }) || {};
	const id = authenticatedId || publicPortalId;
	const isPublicDocument = publicPortalId ? true : false;
	const { t } = useTranslation("documents");
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [dialogs, setDialogs] = useState({});
	const [policyDetails, setPolicyDetails] = useState(null);
	const [timeline, setTimeline] = useState(null);
	const [documentHistory, setDocumentHistory] = useState(null);
	const [accessLogs, setAccessLogs] = useState(null);
	const [editing, setEditing] = useState(false);
	const dispatch = useDispatch();
	const showSignIn = useSignInDialog();
	const { agendaItemId } = queryString.parse(location.search) || {};
	const [agendaItem, setAgendaItem] = useState(null);
	const [agendaItemEditor, setAgendaItemEditor] = useState(null);
	const [failedFileUploads, setFailedFileUploads] = useState(null);
	const [loading, setLoading] = useState(false);
	const [detailsLoading, setDetailsLoading] = useState(false);
	const [prevGuid, setPrevGuid] = useState(null);
	const [nextGuid, setNextGuid] = useState(null);
	const [openPanel, setOpenPanel] = useState(false);
	const [selectedPolicies, setSelectedPolicies] = useState([]);
	const [backButtonClicked, setBackButonClicked] = useState(false);
	const [savingState, setSavingState] = useState({
		isSaving: false,
		saveStatus: null,
		saveTooltip: "",
		isUpdating: false,
		uploadQueue: [],
		uploadingStatus: null,
		uploadingTooltip: "",
		failedUploads: [],
	});
	const savingStateRef = useRef(savingState);
	const editedDocLoadCount = useRef({ oldModifiedDate: "", count: 0 });
	const { testSite, lite } = useContext(SettingsContext);
	const { user: { id: userId = 0 } = {} } = useContext(UserContext);
	const { publishedPermissionsData: { publishedWrite, publishedRead, unpublished } = {} } = useSelector(
		(state) => state.policyGovernanceReducer,
	);

	const { canUpdate, parentFolderType, path, title = "", code } = policyDetails || {};

	useEffect(() => {
		if (unpublished) {
			telemetryAddEvent(`Policy - editing`);
			setEditing(false);
		} else {
			telemetryAddEvent(`Policy - view`);
			setEditing(false);
		}
	}, [publishedWrite, publishedRead, unpublished]);

	useEffect(() => {
		if (policyDetails && selectedPolicies.length === 0) {
			setSelectedPolicies([
				{
					guid: policyDetails.guid,
					status: policyDetails.policyStatus,
				},
			]);
		}
	}, [policyDetails]);

	const removeLastElement = () => {
		return new Promise((resolve) => {
			setSelectedPolicies((prevState) => {
				const newArray = [...prevState.slice(0, -1)];
				resolve(newArray);
				return newArray;
			});
		});
	};

	useEffect(() => {
		if (backButtonClicked) {
			removeLastElement().then(() => {
				setBackButonClicked(false);
			});
		}
	}, [backButtonClicked]);

	const openSidePanelForPublished = () => {
		setOpenPanel(true);
	};

	const handleSelectedPolicies = (entry) => {
		setSelectedPolicies((prev) => [
			...prev,
			{
				guid: entry.guid,
				status: entry.status,
			},
		]);
	};

	let editableExtensions = [".doc", ".docx", ".ppt", ".pptx", ".wopitest", ".wopitestx"];
	if (testSite) {
		editableExtensions = editableExtensions.concat([".htm", ".html"]);
	}

	const handlePreviousforPolicy = () => {
		setBackButonClicked(true);
		if (!userId) {
			// Public user
			navigate(-1);
		} else if (policyDetails) {
			if (selectedPolicies.length > 1) {
				navigate(`/policydoc/${selectedPolicies[selectedPolicies.length - 2].guid}`);
			} else if (parentFolderType > 0 && parentFolderType !== FOLDER_TYPE_POLICY_REVISION_HISTORY && path && path.length > 0) {
				const rootFolder = getPathType(parentFolderType);
				navigate(`/policy/${rootFolder}${path.length > 1 ? `/${path[path.length - 1].guid}` : ""}`);
			} else if ((!canUpdate && parentFolderType === 0) || parentFolderType === FOLDER_TYPE_POLICY_REVISION_HISTORY) {
				if (path && path.length > 0) {
					navigate(`/policy/policybook/${path[path.length - 1]?.guid}`);
				} else {
					navigate(-1);
				}
			} else navigate(-1);
		} else {
			navigate("/");
		}
	};

	const onPrevClick = () => {
		if (prevGuid.length >= 1) {
			navigate(`/policydoc/${prevGuid[prevGuid.length - 1]}`);
		}
	};
	const onNextClick = () => {
		if (nextGuid.length >= 1) {
			navigate(`/policydoc/${nextGuid[0]}`);
		}
	};
	const getPrevAndNextButtons = () => {
		return (
			<Box mr={2} className={classes.buttons}>
				{prevGuid && prevGuid.length !== 0 && (
					<ButtonWithTooltip primary size="small" variant={"outlined"} className={classes.prevAndNextButtons} onClick={() => onPrevClick()}>
						{t("buttons.previousButton")}
					</ButtonWithTooltip>
				)}
				{nextGuid && nextGuid.length !== 0 && (
					<ButtonWithTooltip primary size="small" variant={"outlined"} className={classes.prevAndNextButtons} onClick={() => onNextClick()}>
						{t("buttons.nextButton")}
					</ButtonWithTooltip>
				)}
			</Box>
		);
	};

	const loadDocument = (id = 0, wopiEditDone) => {
		setDocumentHistory(null);
		setAccessLogs(null);

		const history = window.history;
		dispatch(loadPolicyBook(id, true))
			.then((res) => {
				if (res.status === 200 && res?.body?.guid !== null) {
					const { body: document } = res || {};
					if (document) {
						setPolicyDetails(document);
						setPrevGuid(document.previousGuid);
						setNextGuid(document.nextGuid);
						const { draftGuid = null, canUpdate, policyStatus } = document;
						dispatch({
							type: GET_PUBLISHED_PERMISSIONS,
							payload: {
								draftGuid,
								canUpdate,
								reviewStatus: policyStatus === 5 || policyStatus === 0,
							},
						});
					}

					if (wopiEditDone) {
						if (editedDocLoadCount.current.oldModifiedDate == document.dateModified && editedDocLoadCount.current.count < 10) {
							setDetailsLoading(true);
							editedDocLoadCount.current.count += 1;
							setTimeout(() => {
								loadDocument(id, true);
							}, 1000);
						} else {
							setDetailsLoading(false);
						}
					}
				} else {
					throw res;
				}
			})
			.catch(async (err) => {
				if (err?.body?.guid === null) {
					displayErrorNotification("Unable to fetch Policy Details Please try again", dispatch);
				} else {
					displayErrorNotification(err?.response?.body || "Something went wrong. Please try again", dispatch);
				}
				if (history.length > 1) {
					navigate(-1);
				}
			});
	};

	const handleLoadTimeline = () => {
		dispatch(policyTimeline(id))
			.then((res) => {
				const { body: timeline = [] } = res || {};
				setTimeline(timeline);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification("Unable to load the timeline. Please try again", dispatch);
				} else {
					displayErrorNotification(err?.response?.body || "Something went wrong. Please try again", dispatch);
				}
			});
	};

	const handleLoadHistory = useCallback(
		(page, resolve) => {
			let pageToLoad = page;
			if (documentHistory && documentHistory.page >= pageToLoad) {
				pageToLoad = documentHistory.page + 1;
			}
			telemetryAddEvent(`Policy - viewing edit history`);
			dispatch(getLoadHistory(id, pageToLoad))
				.then((res) => {
					const {
						body: { total, changeSets },
					} = res || {};
					if (changeSets) {
						let filteredChangeSets = changeSets.filter(
							(changeSet) => changeSet.fieldChanges.length > 0 || changeSet.relationshipChanges.length > 0,
						);

						setDocumentHistory((prev) => {
							const prevChangeSets = (prev || {}).changeSets || [];

							return {
								page: pageToLoad,
								total: changeSets.length > 0 ? total : prevChangeSets.length, // If the API still returns changesets, assume the API total is valid, otherwise set the total to the number of filtered change sets
								changeSets: prevChangeSets.concat(filteredChangeSets),
							};
						});
					}

					resolve();
				})
				.catch((err) => {
					console.log(err);
				});
		},
		[documentHistory],
	);

	const handleLoadAccessLogs = (page, resolve) => {
		dispatch(policyAccessLogs(id, page))
			.then((res) => {
				const { body: accessLogs = [] } = res || {};
				setAccessLogs(accessLogs);

				resolve();
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification("Unable to Access the logs. Please try again", dispatch);
				} else {
					displayErrorNotification(err?.response?.body || "Something went wrong. Please try again", dispatch);
				}
			});
	};

	const handleRename = (key, value) => {
		key === "title" && value === (null || "")
			? ""
			: (key === "title" || key === "code") && value === policyDetails[key]
				? ""
				: dispatch(policyRename(policyDetails.guid, key, value, policyDetails.changeSetId))
						.then((res) => {
							if (res.status === 200 && res?.body?.guid !== null) {
								setPolicyDetails({ ...policyDetails, ...res.body });
								setDocumentHistory(null);
								displaySuccessNotification(t("policy.successMsg.updatePolicy"), dispatch);
							} else {
								throw res;
							}
						})
						.catch((err) => {
							if (err?.body?.guid === null) {
								displayErrorNotification(t("policy.errorMsg.updatePolicy"), dispatch);
							} else {
								displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
							}
						});
	};

	const handleHtmlEdit = debounce((_event, editor, field) => {
		const html = editor.getData();
		if (html && html !== policyDetails.html && policyDetails.canUpdate) {
			request
				.patch(`${API_HOST}/api/policy/${policyDetails.guid}/detail`)
				.withCredentials()
				.send({ html })
				.then((res) => {
					setPolicyDetails({ ...policyDetails, ...res.body });
					setDocumentHistory(null);

					enqueueSnackbar(t("detail.snackbar.save.success"));
				})
				.catch((err) => {
					showSignIn(err, () => {
						handleHtmlEdit(html);
					});
				});
		}
	}, 2000);

	const handleDownload = useCallback(() => {
		setLoading(true);
		dispatch(downloadPolicy(policyDetails.guid))
			.then((res) => {
				setLoading(false);
				const { Name, mimeType, Document } = res;
				downloadFile(Name, mimeType, Document);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.downloadPolicy"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
				setLoading(false);
			});
	}, [policyDetails]);

	const handlePublishPolicy = useCallback(() => {
		setLoading(true);
		dispatch(publishPolicy(policyDetails.guid))
			.then((res) => {
				setLoading(false);
				displaySuccessNotification(t("policy.successMsg.publish"), dispatch);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.publish"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
				setLoading(false);
			});
	}, [policyDetails]);

	const handleunpublishPolicy = useCallback(() => {
		setLoading(true);
		dispatch(unpublishPolicy(policyDetails.guid))
			.then((res) => {
				setLoading(false);
				displaySuccessNotification(t("policy.successMsg.unpublishPolicy"), dispatch);
			})
			.catch((err) => {
				if (err.status === 500) {
					displayErrorNotification(t("policy.errorMsg.unpublishPolicy"), dispatch);
				} else {
					displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
				}
				setLoading(false);
			});
	}, [policyDetails]);

	const handleReplace = (e) => {
		const { target: { files = [] } = {} } = e;

		// Only get files with allowed extensions
		const filteredFiles = [];
		const invalidFiles = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			if (endsWithAllowedExtensionForPolicy(file.name)) {
				filteredFiles.push(file);
			} else {
				invalidFiles.push(file);
			}
		}

		if (filteredFiles.length > 0) {
			setDialogs({
				upload: {
					files: filteredFiles
						.sort((first, second) =>
							// eslint-disable-next-line no-nested-ternary
							first.webkitRelativePath < second.webkitRelativePath ? -1 : first.webkitRelativePath > second.webkitRelativePath ? 1 : 0,
						)
						.map((file) => ({
							file,
							name: trimChars("\\/", file.webkitRelativePath || file.name),
							path: trimChars("\\/", file.webkitRelativePath.replace(file.name, "")),
						})),
					invalidFiles,
				},
			});
		} else if (invalidFiles.length > 0) {
			setDialogs({
				failedUploads: invalidFiles,
			});
		}
	};

	const handleCopyMove = () => {
		setDialogs({ copyMove: true });
	};

	const afterMove = (policy, move, path) => {
		displaySuccessNotification(t(`policy.successMsg.${move ? `movePolicy` : `copyPolicy`}`, { path }), dispatch);

		if (move) {
			setPolicyDetails(policy);
		} else {
			window.open(`${API_HOST}/home/policy/policybook/${policy.guid}`, "_blank");
		}
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const closeUpload = (failedUploads = []) => {
		if (failedUploads.length === 0) {
			closeDialogs();
		} else {
			setDialogs({
				failedUploads,
			});
		}
	};

	const afterUploadFile = (policy) => {
		setPolicyDetails((prev) => ({
			...prev,
			extension: policy.extension,
			size: policy.size,
			dateModified: policy.dateModified,
			modifiedBy: policy.modifiedBy,
		}));
		setDocumentHistory(null);
	};

	const loadMoreHistory = useCallback(
		({ startIndex }) =>
			new Promise((resolve) => {
				handleLoadHistory(Math.floor(startIndex / 25.0) + 1, resolve);
			}),
		[handleLoadHistory],
	);

	const toggleFieldChanges = (changeSet) => {
		changeSet.expanded = !changeSet.expanded;

		setDocumentHistory((prev) => ({ ...prev }));
	};

	const loadMoreAccessLogs = ({ startIndex }) =>
		new Promise((resolve) => {
			handleLoadAccessLogs(Math.floor(startIndex / 25.0) + 1, resolve);
		});

	const handleExport = () => {
		window.location.href = `/filepro/document/exportaccesslog/${id}/`;
	};

	const handleView = useCallback(
		(changeSet) => {
			window.open(`/document/${policyDetails.guid}?changeSetId=${changeSet.changeSetId}`, "_blank");
		},
		[policyDetails],
	);

	const handleRestore = useCallback(
		(changeSet) => {
			dispatch(restorePolicyDetails(policyDetails.guid, changeSet.changeSetId))
				.then(() => {
					loadDocument(policyDetails.guid);
					setDocumentHistory(null);
					displaySuccessNotification(
						t("detail.snackbar.restore.success", {
							dateChanged: formatDate(changeSet.dateChanged, changeSet.dateChanged, undefined, t("app:at"), "", "", true, true, true),
						}),
						dispatch,
					);
				})
				.catch((err) => {
					if (err.status === 403) {
						displayErrorNotification(t("policy.errorMsg.accessDenied"), dispatch);
					} else if (err.status === 500) {
						displayErrorNotification(t("policy.errorMsg.restorePolicyErr"), dispatch);
					} else {
						displayErrorNotification(t("policy.errorMsg.commonMsg"), dispatch);
					}
					showSignIn(err, () => {
						handleRestore(changeSet);
					});
				});
		},
		[policyDetails],
	);

	const editDocument = (draftGuid) => {
		if (canUpdate) {
			window.open(`/home/policydoc/edit/${draftGuid}`, "_blank");
		} else {
			const msg = {
				MessageId: "App_IsFrameTrusted",
				SendTime: Date.now(),
			};
			if (window && window.parent) {
				window.parent.postMessage(msg, window.location.origin);
			}
			editedDocLoadCount.current = { oldModifiedDate: "", count: 0 };
		}
	};

	const handleBack = (publishedGuid = null) => {
		if (publishedGuid) {
			navigate(getPathForEditPage(publishedGuid, null));
			window.location.reload();
		} else {
			editedDocLoadCount.current.oldModifiedDate = policyDetails.dateModified;
			loadDocument(policyDetails.guid, true);
		}
		setEditing(false);
	};

	// Agenda item functions
	const loadAgendaItem = (id) => {
		if (id) {
			request
				.get(`${API_HOST}/api/agendaitem/${id}`)
				.withCredentials()
				.then((res) => {
					if (res.body) {
						setAgendaItem(res.body.item);
						setEditing(true);
					}
				})
				.catch((err) => {
					showSignIn(
						err,
						() => {
							loadItem(id);
						},
						() => {
							setRequestError(err);
						},
					);
				});
		} else {
			// New item
			saveItem(
				{
					id: 0,
					guid: uuid(),
					name: "",
					text: "",
				},
				true,
			);
		}
	};

	const checkUploadStatus = () => {
		const { uploadQueue } = savingState;

		let status = null;
		let tooltip = null;
		for (let i = 0; i < uploadQueue.length; i++) {
			if (!uploadQueue[i].complete) {
				if (tooltip) {
					tooltip += ` / ${uploadQueue[i].file.name}`;
				} else {
					tooltip = uploadQueue[i].file.name;
				}
			}
		}
		if (tooltip) {
			status = t("agendaMenu:uploading");
		}
		setSavingState((prev) => ({
			...prev,
			uploadingStatus: status,
			uploadingTooltip: tooltip,
		}));
	};

	const queueFileUploads = (guid, fileUploads, fileData) => {
		for (let i = 0; i < fileUploads.length; i++) {
			setSavingState((prev) => {
				prev.uploadQueue.push({
					guid,
					fileGuid: fileUploads[i].guid,
					file: fileUploads[i],
					complete: false,
					error: null,
				});

				return {
					...prev,
					uploadQueue: prev.uploadQueue,
					failedUploads: [],
				};
			});
		}
		checkUploadStatus();

		sendFiletoAPI(fileData);
	};

	const invalidFileExtension = (guid, fileNames) => {
		if (fileNames.length > 0) {
			setFailedFileUploads(fileNames);
		}
	};

	useEffect(() => {
		dispatch(
			updatePageConfigs({
				title: policyDetails ? policyDetails.title : "",
				back: {
					action: () => navigate(-1),
				},
				telemetryPage,
				contentPaper: { transparent: true },
			}),
		);
		if (title !== "") {
			let tabTitle = code ? `${code} ${title}` : title;
			document.title = `${tabTitle} - ${t("app:productName")}}`;
		}
	}, [policyDetails, prevGuid, nextGuid]);

	useEffect(() => {
		dispatch(resetPageConfigs({}));

		loadDocument(id);
		if (agendaItemId) {
			loadAgendaItem(agendaItemId);
		}
	}, [id]);

	return !loading ? (
		<>
			<PublicDocumentContext.Provider value={isPublicDocument}>
				<DocumentTopBar
					handlePreviousforPolicy={handlePreviousforPolicy}
					handleBack={handleBack}
					editDocument={
						policyDetails && policyDetails.canUpdate && editableExtensions.includes(policyDetails.extension) && !publishedRead
							? editDocument
							: null
					}
					editing={editing}
					savingState={savingState}
					handleSaveAndClose={agendaItem ? saveAgendaItem : null}
					telemetryPage={telemetryPage}
					isPolicy={true}
					policy={policyDetails}
					getPrevAndNextButtons={getPrevAndNextButtons()}
					id={id}
					openSidePanelForPublished={openSidePanelForPublished}
					handleSelectedPolicies={handleSelectedPolicies}
				/>
			</PublicDocumentContext.Provider>
			<ComponentContainer padding="72px 0 0 0">
				{policyDetails ? (
					<div>
						<div className={clsx({ [classes.agendaItemDocument]: agendaItem })}>
							<PublicDocumentContext.Provider value={isPublicDocument}>
								<Policy
									ref={editedDocLoadCount}
									detailsLoading={detailsLoading}
									id={CONTENT_SCROLL_ID}
									policy={policyDetails}
									timeline={timeline}
									history={documentHistory}
									accessLogs={accessLogs}
									telemetryPage={telemetryPage}
									handleRename={handleRename}
									handleHtmlEdit={handleHtmlEdit}
									handleDownload={handleDownload}
									handleReplace={handleReplace}
									handleCopyMove={handleCopyMove}
									handleLoadTimeline={handleLoadTimeline}
									loadMoreHistory={loadMoreHistory}
									toggleFieldChanges={toggleFieldChanges}
									handleView={handleView}
									handleRestore={handleRestore}
									loadMoreAccessLogs={loadMoreAccessLogs}
									handleExport={handleExport}
									editing={editing}
									agendaItem={agendaItem}
									handlePublishPolicy={handlePublishPolicy}
									handleunpublishPolicy={handleunpublishPolicy}
									openPanel={openPanel}
									handleSelectedPolicies={handleSelectedPolicies}
									selectedPolicies={selectedPolicies}
									backButtonClicked={backButtonClicked}
								/>
							</PublicDocumentContext.Provider>
						</div>
						{agendaItem && agendaItem.canUpdate && (
							<div className={classes.agendaItemDescription}>
								<GenericEditor
									key={`agenda-item-editor`}
									toolbar="limited"
									name="text"
									title={t("agendaItems:editItem.addAttachmentsToDescription")}
									labelSize="large"
									setEditorsData={(editor) => {
										setAgendaItemEditor(editor);
									}}
									onChange={(_event, editor, field) => {
										setAgendaItem((prev) => ({ ...prev, text: editor.getData() }));
									}}
									content={agendaItem.text || ""}
									handleUndo={() => {}}
									handleRedo={() => {}}
									queueFileUploads={queueFileUploads}
									invalidFileExtension={invalidFileExtension}
									guid={agendaItem.guid}
									features={[
										{
											id: "MOA",
											label: t("meetings:inlineFile.features.MOA.featureLabel"),
											className: "closed",
											defaultValue: true,
											disabledValue: false,
											isEnabled: true,
											anchorTitle: t("meetings:inlineFile.features.MOA.anchorTitleMember"),
											tooltipDisabledOn: t("meetings:inlineFile.features.MOA.tooltipDisabledOn"),
										},
									]}
									mt={2}
									disabled={!agendaItem.canUpdate}
								/>
							</div>
						)}
					</div>
				) : (
					<CircularProgressIndicator />
				)}
				{dialogs.upload && (
					<UploadDialog
						replace
						id={policyDetails.guid}
						files={dialogs.upload.files}
						invalidFiles={dialogs.upload.invalidFiles}
						onClose={closeUpload}
						afterUploadFile={afterUploadFile}
						showSignIn={showSignIn}
						telemetryPage={telemetryPage}
					/>
				)}

				{dialogs.failedUploads && (
					<FileUploadFailureDialog
						show={dialogs.failedUploads && dialogs.failedUploads.length > 0}
						failedFileUploads={dialogs.failedUploads}
						onClose={closeDialogs}
						isPolicy={true}
					/>
				)}
				{dialogs.copyMove && <PolicyMoveCopyDialog document={policyDetails} onClose={closeDialogs} afterMove={afterMove} move />}
			</ComponentContainer>
		</>
	) : (
		<>
			<div className={classes.overlayLoader} />
			<CircularProgressIndicator className={classes.loader} />
		</>
	);
};

export default withErrorHandling(PolicyContainer);
